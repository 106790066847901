/**
 * ErrorMixin for a vue component
 *
 * @property {boolean} isError Error state
 * @property {string} errorMessage Message of the error
 * @property {function} toggleError Toggle the error state on and off
 **/
const errorMixin = {
  data() {
    return {
      isError: false,
      errorMessage: null,
    };
  },
  methods: {
    toggleError(msg) {
      this.errorMessage = msg;
      this.isError = !this.isError;
    },
  },
};

export default errorMixin;
