import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import { cleanPayload, getParams } from "@/services/api/utils";
import "./types";

// TODO Implement all routes!!!
const PROJECT_URI = Settings.API_URI_PROJ;
const PROJECT_URI_NEW = Settings.API_URI_PROJECT_V2;

/**
 * Get a project
 * @param {string} id ID of the project
 * @return {Promise<ProjectObject>}
 * @throws {APIError}
 **/
const getProject = (id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${PROJECT_URI}/`,
      params: getParams({ project_id: id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Create a new project
 * @param {ProjectCreateObject} data Payload
 * @return {Promise<ProjectObject>}
 * @throws { APIError }
 **/
const createProject = (data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "post",
      url: `${PROJECT_URI}/`,
      data: cleanPayload(data),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Update a project
 * @param { string } id ID of the project
 * @param { ProjectUpdateObject } data Payload
 * @return { Promise<ProjectObject> }
 * @throws { APIError }
 **/
const updateProject = (id, data) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "patch",
      url: `${PROJECT_URI}/`,
      params: getParams({ project_id: id }),
      data: cleanPayload(data),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Delete a project
 * @param {string} project_id ID of the project
 **/
const deleteProject = (project_id) => {
  let config = {
    method: "delete",
    url: `${PROJECT_URI}/`,
    params: getParams({ project_id: project_id }),
  };

  return Interceptor(config);
};

/**
 * Get users of a project
 * @param {string} id ID of the project
 * @return {Promise<UserObject[]>}
 * @throws {APIError}
 **/
const getProjectUsers = (id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${PROJECT_URI}/users`,
      params: getParams({ project_id: id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get users of a project
 * @param {string} project_id ID of the project
 * @param {string} user_id ID of the user
 * @return {Promise<UserObject[]>}
 * @throws {APIError}
 **/
const addUserToProject = (project_id, user_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "put",
      url: `${PROJECT_URI}/users/add`,
      params: getParams({ project_id: project_id, user_id: user_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get users of a project
 * @param {string} project_id ID of the project
 * @param {string} user_id ID of the user
 * @return {Promise<UserObject[]>}
 * @throws {APIError}
 **/
const removeUserFromProject = (project_id, user_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "delete",
      url: `${PROJECT_URI}/users/remove`,
      params: getParams({ project_id: project_id, user_id: user_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get all contacts of a project
 * @param {string} project_id ID of the project
 * @return {Promise<ContactObject[]>}
 * @throws {APIError}
 **/
const getProjectContacts = (project_id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${PROJECT_URI}/contacts`,
      params: getParams({ project_id: project_id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get activities of a project
 * @param {string} id ID of the project
 * @return {Promise<ProjectActivityObject[]>}
 * @throws {APIError}
 **/
const getProjectActivities = (id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${PROJECT_URI}/activity`,
      params: getParams({ project_id: id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get survey of a project
 * @param { string } id ID of the project
 * @return { Promise<SurveyObject> }
 * @throws { APIError }
 **/
const getProjectSurvey = (id) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: "get",
      url: `${PROJECT_URI}/survey`,
      params: getParams({ project_id: id }),
    };

    Interceptor(config)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

/**
 * Get survey of a project
 * @param { string } id ID of the project
 * @return { AxiosPromise }
 * @throws { APIError }
 **/
const deleteAllProjectContacts = (id) => {
  let config = {
    method: "delete",
    url: `${PROJECT_URI}/contacts`,
    params: getParams({ project_id: id }),
  };

  return Interceptor(config);
};

const getProjectContactsCount = (id) => {
  let config = {
    method: "get",
    url: `${PROJECT_URI_NEW}/contacts/count`,
    params: getParams({ project_id: id }),
  };

  return Interceptor(config);
};

export {
  getProject,
  createProject,
  updateProject,
  deleteProject,
  getProjectUsers,
  addUserToProject,
  removeUserFromProject,
  getProjectContacts,
  getProjectActivities,
  getProjectSurvey,
  deleteAllProjectContacts,
  getProjectContactsCount,
};
